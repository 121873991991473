/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { every, reduce } from 'lodash';

import { chunkify } from '../utils/chunkify';
import { download } from '../utils/fileDownload';
import parseFieldData from '../utils/parseFieldData';
import { getConfiguration } from '../utils/wasmConfigure-react';

import type {
  GeometryViewerModule,
  GeometryViewer,
  FieldData,
  ViewportLayoutOption,
} from '../components/GeometryViewer/GeometryViewerFactory';

// set chunk size to 100mb
// eslint-disable-next-line no-magic-numbers
export const MAX_CHUNK_SIZE = 100 * 1000 * 100;

export function numViewports(layout: ViewportLayoutOption) {
  const rowsCols = layout.split('x').map((d) => parseInt(d, 10));
  return reduce(rowsCols, (acc, num) => acc * num, 1);
}

export type MeshConfig = { filename: string; url: string; viewportId: number };
export type MeshMetadata = string[][];

interface UseMeshVisualiserProps {
  layout?: ViewportLayoutOption;
  meshes: MeshConfig[];
  onLoaded?: () => void;
}

function useMeshVisualiser({ meshes, layout = '1x1' }: UseMeshVisualiserProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wasmModule = useRef<GeometryViewerModule | null>(null);
  const viewer = useRef<GeometryViewer>();
  const [viewApi] = useState('webgl');
  const [colorArrays, setColorArrays] = useState<string[]>(['Solid']);
  const [colorMapPresets, setColorMapPresets] = useState<string[]>(['Warm']);
  const [metadata, setMetadata] = useState<MeshMetadata[]>();

  //  states
  const [isDownloading, setIsDownloading] = useState(false);
  const [isParsing, setIsParsing] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const loadFile = useCallback(
    async (file: File, viewportId = 0) => {
      if (wasmModule.current === null || wasmModule.current === undefined) {
        return;
      }
      if (viewer.current === null || viewer.current === undefined) {
        return;
      }

      try {
        const chunks = chunkify(file, MAX_CHUNK_SIZE);
        let offset = 0;

        /* eslint-disable no-underscore-dangle */
        const ptr = wasmModule.current._malloc(file.size);
        for (let i = 0; i < chunks.length; ++i) {
          const chunk = chunks[i];
          // eslint-disable-next-line no-await-in-loop
          const data = new Uint8Array(await chunk.arrayBuffer());
          wasmModule.current.HEAPU8.set(data, ptr + offset);
          offset += data.byteLength;
        }
        viewer.current.addMesh(file.name, ptr, file.size, viewportId);
        wasmModule.current._free(ptr);
        /* eslint-disable no-underscore-dangle */
      } catch (err) {
        console.error('Error loading and parsing the mesh file');
        console.error(err);
      }
    },
    [viewer, wasmModule],
  );
  // TODO provide this as a function to the consumer so that they can lazy load meshes
  const downloadAndParseMeshFiles = useCallback(
    async (meshConfigs: MeshConfig[]) => {
      setIsDownloading(true);

      const meshFilePromises = meshConfigs
        .filter((m) => m.filename && m.url)
        .map(async ({ filename, viewportId, url }) => {
          const { blob } = await download(url);
          return { filename, meshFile: new File([blob], filename), viewportId };
        });

      const files = await Promise.all(meshFilePromises);
      setIsDownloading(false);

      setIsParsing(true);
      const loadFilePromises = files.map(({ meshFile, viewportId }) => {
        return loadFile(meshFile, viewportId);
      });

      await Promise.all(loadFilePromises);
      setIsParsing(false);
    },
    [loadFile],
  );

  useEffect(() => {
    async function initViewer(canvasElement: HTMLCanvasElement) {
      //  dynamically import wasm viewer so that webpacks splits it
      //  into it's own bundle which can be cached
      const { default: createGeometryViewerModule } = await import(
        // @ts-ignore
        '../components/GeometryViewer/GeometryViewer'
      );

      const webgpuDevice: GPUDevice | null = null;
      const configuration: any = await getConfiguration(
        viewApi,
        webgpuDevice,
        canvasElement,
      );

      // Initalize wasm module
      wasmModule.current = await createGeometryViewerModule(configuration);
      // Create GeometryViewer instance
      viewer.current = new wasmModule.current!.GeometryViewer(layout);
      viewer.current.initialize();
      // starts processing events on browser main thread.
      viewer.current.start();
      const viewportCount = numViewports(layout);

      if (
        meshes.length <= viewportCount &&
        every(
          meshes.map((d) => d.viewportId),
          (vId) => vId <= viewportCount - 1,
        )
      ) {
        // Download and load meshes into viewer
        await downloadAndParseMeshFiles(meshes);

        // Extract ColorArrays
        const pointArrays = viewer.current.getPointDataArrays().split(';');
        const cellArrays = viewer.current.getCellDataArrays().split(';');
        const allArrays = ['Solid', ...pointArrays, ...cellArrays].filter(
          (el) => {
            return el.length > 0;
          },
        );

        console.log('All arrays', allArrays);
        viewer.current.setColorByArray(
          allArrays.length > 1 ? allArrays[1] : allArrays[0],
        );
        setColorArrays(allArrays);

        // Extract Color Presets
        const presets = viewer.current.getColorMapPresets().split(';');
        viewer.current.setColorMapPreset('Warm');
        setColorMapPresets([...presets]);

        //  Parse field data if any
        const fieldData = JSON.parse(
          viewer.current.getFieldData(),
        ) as FieldData[][];
        if (fieldData) {
          const formattedMetadata = parseFieldData(fieldData);
          setMetadata(formattedMetadata);
        }

        viewer.current.resetView();
        viewer.current.render();
        setInitialized(true);
      }
    }

    if (canvasRef.current) {
      initViewer(canvasRef.current);
    }

    return () => {
      // Clean up viewer instance
      if (viewer.current) {
        viewer.current = undefined;
      }

      // Set the wasmModule reference to null
      wasmModule.current = null;
    };
  }, [viewApi, canvasRef, layout, downloadAndParseMeshFiles]);

  const isLoading = useMemo(
    () => isDownloading || isParsing,
    [isDownloading, isParsing],
  );

  return {
    canvasRef,
    colorArrays,
    colorMapPresets,
    initialized,
    isLoading,
    metadata,
    viewer,
  };
}

export default useMeshVisualiser;
