/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';

import { Button } from '@pxui/components/ui';

interface ClipSettings {
  normal: { x: number; y: number; z: number };
  origin: { x: number; y: number; z: number };
  showOutline: boolean;
  showPlane: boolean;
}

export interface ClipPropertiesPanelProps {
  onClipSettingsChange: (settings: ClipSettings) => void;
  onSetClipPlaneNormal: (x: number, y: number, z: number) => void;
  onToggleClipOutlineVisibility: (show: boolean) => void;
  onToggleClipPlaneVisibility: (show: boolean) => void;
  triggerClip: () => void;
}

export const ClipPropertiesPanel: React.FC<ClipPropertiesPanelProps> = ({
  onClipSettingsChange,
  onToggleClipPlaneVisibility,
  onToggleClipOutlineVisibility,
  onSetClipPlaneNormal,
  triggerClip,
}) => {
  const [showPlane, setShowPlane] = useState<boolean>(true);
  const [showOutline, setShowOutline] = useState<boolean>(true);
  const [origin] = useState<{ x: number; y: number; z: number }>({
    x: 0,
    y: 0,
    z: 0,
  });
  const [normal, setNormal] = useState<{ x: number; y: number; z: number }>({
    x: 1,
    y: 0,
    z: 0,
  });

  // Update clipping settings whenever state changes
  useEffect(() => {
    onClipSettingsChange({ normal, origin, showOutline, showPlane });
  }, [showPlane, showOutline, origin, normal, onClipSettingsChange]);

  const handleShowPlaneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onToggleClipPlaneVisibility(e.target.checked);
      setShowPlane(e.target.checked);
    },
    [onToggleClipPlaneVisibility],
  );

  const handleShowOutlineChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onToggleClipOutlineVisibility(e.target.checked);
      setShowOutline(e.target.checked);
    },
    [onToggleClipOutlineVisibility],
  );

  const handleNormalChange = useCallback(
    (x: number, y: number, z: number) => {
      onSetClipPlaneNormal(x, y, z);
      setNormal({ x, y, z });
    },
    [onSetClipPlaneNormal, setNormal],
  );

  const setNormalToX = () => handleNormalChange(1, 0, 0);
  const setNormalToY = () => handleNormalChange(0, 1, 0);
  const setNormalToZ = () => handleNormalChange(0, 0, 1);

  return (
    <div className="p-2 border border-01-subtle rounded-xl text-primary text-sm px-[10px]">
      <div className="mb-1">Plane Parameters</div>

      <div>
        <label>
          <input
            className="mr-1"
            type="checkbox"
            checked={showPlane}
            onChange={handleShowPlaneChange}
          />
          Show Plane
        </label>
      </div>

      <div className="mb-3">
        <label>
          <input
            className="mr-1"
            type="checkbox"
            checked={showOutline}
            onChange={handleShowOutlineChange}
          />
          <span>Show Outline</span>
        </label>
      </div>

      <div className="mb-1">Normal Shortcuts</div>
      <div className="flex gap-2 mb-3">
        <Button onClick={setNormalToX} layout="textOnly" variant="secondary">
          X Normal
        </Button>
        <Button onClick={setNormalToY} layout="textOnly" variant="secondary">
          Y Normal
        </Button>
        <Button onClick={setNormalToZ} layout="textOnly" variant="secondary">
          Z Normal
        </Button>
      </div>
      <Button layout="textOnly" onClick={triggerClip} className="w-28">
        Clip
      </Button>
    </div>
  );
};

export default ClipPropertiesPanel;
