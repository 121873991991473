import { MutableRefObject, useCallback, useState } from 'react';

import { Button } from '@pxui/components/ui';

import { useClipFilterProps } from '../../hooks/useClipFilterProps';
import { ClipPropertiesPanel } from '../ClipPlaneProperties/ClipPlaneProperties';
import { GeometryViewer } from '../GeometryViewer/GeometryViewerFactory';

export enum FilterTypes {
  CLIP = 'clip',
}
export interface Filter {
  type: FilterTypes;
}

interface FiltersProps {
  isEnabled: boolean;
  viewer?: MutableRefObject<GeometryViewer | undefined>;
}
export function Filters({ viewer, isEnabled }: FiltersProps) {
  const clipFilterProps = useClipFilterProps(viewer);
  const [filters, setFilters] = useState<Filter[]>([]);

  const addClipFilter = useCallback(() => {
    clipFilterProps.onActivateFilter();
    setFilters((curFilters) => [...curFilters, { type: FilterTypes.CLIP }]);
    // Call viewer.enableClipFilter();
  }, [clipFilterProps]);

  const removeClipFilter = useCallback(() => {
    clipFilterProps.onDeactivateFilter();
    setFilters([]);
    // viewer.disableClipFilter();
  }, [clipFilterProps]);

  const renderFilter = (filter: Filter, id: number) => {
    switch (filter.type) {
      case FilterTypes.CLIP:
        return <ClipPropertiesPanel key={id} {...clipFilterProps} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="mb-2">
        {filters.length === 0 ? (
          <Button
            layout="textOnly"
            disabled={!isEnabled}
            onClick={addClipFilter}
            variant="secondary"
          >
            Add Clip Filter
          </Button>
        ) : (
          <Button layout="textOnly" onClick={removeClipFilter} variant="danger">
            Remove Clip Filter
          </Button>
        )}
      </div>

      <div className="flex flex-col">
        {filters.map((filter, idx) => renderFilter(filter, idx))}
      </div>
    </div>
  );
}

export default Filters;
