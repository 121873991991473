import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

import Sidebar from '../Sidebar/Sidebar';
import MeshColorLegend from '@components/MeshViewer/ColorsLegend';
import useMeshVisualiser, { MeshConfig } from '../../hooks/useMeshVisualiser';
import { ViewportLayoutOption } from './GeometryViewerFactory';

interface GeometryViewerProps {
  meshes: MeshConfig[];
}

export function GeometryViewerApp({ meshes }: GeometryViewerProps) {
  const [selectedScalarRange, setSelectedScalarRange] = useState<
    [number, number] | undefined
  >();
  const [selectedColorPreset, setSelectedColorPreset] =
    useState<string>('Warm');

  const layout: ViewportLayoutOption = useMemo(() => {
    if (meshes.length === 1) {
      return '1x1';
    } else if (meshes.length === 2) {
      return '1x2';
    } else if (meshes.length === 3) {
      return '1x3';
    } else if (meshes.length === 4) {
      return '2x2';
    } else {
      throw new Error('Invalid number of meshes');
    }
  }, [meshes]);

  const {
    canvasRef,
    viewer,
    colorArrays,
    colorMapPresets,
    isLoading,
    initialized,
    metadata,
  } = useMeshVisualiser({
    meshes,
    layout,
  });

  const updateScalarRange = () => {
    const scalarRangeArr = viewer?.current?.getActiveScalarRange();
    if (scalarRangeArr) {
      const scalarRange = scalarRangeArr.split(';').map(parseFloat) as [
        number,
        number,
      ];
      setSelectedScalarRange(scalarRange);
    }
  };

  const handleColorPresetChange = useCallback((preset: string) => {
    setSelectedColorPreset(preset);
  }, []);
  // TODO move into useMeshVisualizer
  useEffect(() => {
    if (!isLoading) {
      updateScalarRange();
    }
  }, [isLoading]);

  const isSingleMesh = layout === '1x1';

  return (
    <div className="w-full h-full relative overflow-y-hidden">
      <div id="main-area" className="flex w-full h-full flex-row">
        <div
          id="viewport-area"
          className="w-full h-full flex elevation-00 items-center justify-center relative"
        >
          {isLoading && <LoadingIndicator />}
          <div id="canvas-wrapper">
            <canvas
              id="canvas"
              className="focus:outline-none"
              ref={canvasRef}
            ></canvas>
          </div>
          <div
            className="absolute bottom-8 right-8 z-10"
            style={{ height: '200px' }}
          >
            {selectedScalarRange && selectedColorPreset && (
              <MeshColorLegend
                range={selectedScalarRange}
                colorPreset={selectedColorPreset}
              />
            )}
          </div>
        </div>
        <Sidebar
          meshes={meshes}
          viewer={viewer}
          isSingleMesh={isSingleMesh}
          viewerReady={initialized}
          metadata={metadata}
          onColorByChange={updateScalarRange}
          onColorPresetChange={handleColorPresetChange}
          colorArrays={colorArrays}
          colorMapPresets={colorMapPresets}
        />
      </div>
    </div>
  );
}

export default GeometryViewerApp;
